a, a:hover {
    color: #008cd7;
}
#header {
    text-align: center;
    top: 0;
    position: fixed;
    height: 60px;
    width: 100%;
    z-index: 4;
    background-color: rgba(255,255,255,0.92);
}
#header h1 {
    margin: 10px 0 0 0;
}
#content {
    margin: 60px 0;
}
#welcome {
    background: url('../../images/web/country-select/welcome.jpg');
    background-position: center;
    height: 412px;
    text-align: center;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    display: table;
    width: 100%;
}
#welcome-text {
    display: table-cell;
    vertical-align: middle;
}
#welcome-text p {
    color: #ffffff;
    font-size: 40px;
    font-weight: 700;
    text-shadow: 0 0 10px rgba(0,0,0,0.5),0 0 10px rgba(0,0,0,0.5),0 0 10px rgba(0,0,0,0.5);
}
#choose-text {
    text-align: center;
    border-bottom: 1px solid grey;
    margin-bottom: 20px;
}
.website {
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
}
.website:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
.website img {
    -webkit-filter: drop-shadow(2px 1px 2px rgba(0,0,0,0.3));
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

@media (max-width: 767px) {
    #welcome {
        background: none;
        height: auto;
    }
    #welcome-text p {
        color: #008cd7;
        font-size: 25px;
        text-shadow: none;
    }
}