/*GENERAL SETTINGS*/
body {
    background-color: #ffffff;
}
* {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #696969;
}
*:focus {
    outline: none !important;
}
html, body {height: 100%;}

#wrap {min-height: 100%;}

#main {
    overflow:auto;
}

h1 {
    font-weight: 700;
    color: #000000;
}
h2, .subtitle, .subtitle *{
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;
}
h3 {
    color: #000;
    font-weight: 700;
}
.small-text, .small-text *{
    font-weight: 400;
    color: #b2b2b2;
}
.small-text b {
    font-weight : 700 !important;
}

.align-right {
    text-align: right;
}
.float-clear{
    clear:both;
}

.btnShowMoreContent{
    font-size:x-large;
    text-decoration: none !important;
    color:black !important;
}

.btn-gray{
    background-color: #E6E6E6 !important;
    border:1px solid grey;
}

.btn-dark-gray{
    background-color: #9a9a9a !important;
}
.btn-dark-gray *{
    color: #ffffff;
}

.btn-white {
    background-color: #FFF;
    border: 1px solid #000;
    color: #000;
}
.btn-white *{
    color: #000;
}

.btn-light {
    border: 1px solid #000;
}

.btn-yellow {
    background-color: #fcc33b !important;
    color: #000 !important;
    text-transform: uppercase;
}

.tag>.label{
    display: inline-block;
    padding: 5px;
    margin-bottom: 5px;
    font-size: inherit;
    cursor: pointer;
    white-space: normal;
}
.tag>.label:hover{
    background-color: #009587;
}
.tag:hover{
    text-decoration: none !important;
}
.desc-conlink {
    color: inherit;
    text-decoration: underline;
}

.simpleLink {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
}
.simpleLink:hover {
    color: #696969;
}

.flex-video {
    position: relative;
    padding-top: 25px;
    padding-bottom: 67.5%;
    height: 0;
    margin-bottom: 16px;
    overflow: hidden;
    margin: 0 auto;
    text-align:center;
}

.flex-video.widescreen { padding-bottom: 57.25%; }
.flex-video.vimeo { padding-top: 0; }

.flex-video iframe,
.flex-video object,
.flex-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@media only screen and (max-device-width: 800px), only screen and (device-width: 1024px) and (device-height: 600px), only screen and (width: 1280px) and (orientation: landscape), only screen and (device-width: 800px), only screen and (max-width: 767px) {
    .flex-video { padding-top: 0; }
}

@media (max-width: 767px) {
    /*MOBILE XS*/
    * {
        font-size: 14px;
    }
    h1 {
        font-size: 24px;
    }
    h2, h3, .subtitle, .subtitle *{
        font-size: 16px;
    }
    .small-text, .small-text *{
        font-size: 11px;
    }

    .col-xs-no-gutter{
        padding-right: 0;
        padding-left: 0;
    }
    .col-xs-no-gutter>.row, .row-xs-no-margin{
        margin-right: 0;
        margin-left: 0;
    }
    .text-xs-truncate{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 2em;
        line-height: 2em;
    }

    .buttonShowMore{
        visibility: visible;
    }

    .h1Home{
        text-align:center;
    }
    .availableSticker{
        text-align:center;
        margin-top:0;
    }
}
@media (min-width: 768px) {
    /*TABLET SM*/
    * {
        font-size: 15px;
    }
    h1 {
        font-size: 30px;
    }
    h2, h3, .subtitle, .subtitle *{
        font-size: 20px;
    }
    .small-text, .small-text *{
        font-size: 12px;
    }

    .col-sm-no-gutter{
        padding-right: 0;
        padding-left: 0;
    }
    .col-sm-no-gutter>.row, .row-sm-no-margin{
        margin-right: 0;
        margin-left: 0;
    }
    .text-sm-truncate{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 2em;
        line-height: 2em;
    }

    .buttonShowMore{
        visibility: visible;
    }
    .h1Home{
        text-align:left;
    }
    .availableSticker{
        text-align:right;
        margin-top:-47px;
    }

}
@media (min-width: 992px) {
    /*PC MD-LG*/
    .col-md-no-gutter{
        padding-right: 0;
        padding-left: 0;
    }
    .col-md-no-gutter>.row, .row-md-no-margin{
        margin-right: 0;
        margin-left: 0;
    }
    .col-md-gutter{
        padding-right: 15px;
        padding-left: 15px;
    }
    .col-md-gutter>.row, .row.row-md-margin{
        margin-right: -15px;
        margin-left: -15px;
    }
    .text-md-no-truncate{
        overflow: inherit;
        white-space: inherit;
        text-overflow: inherit;
        height: inherit;
        line-height: inherit;
    }

    .buttonShowMore{
        visibility: hidden;
    }

    .video{
        height: 277px !important;
    }
    .align-right-md{
        text-align: right;
    }
}

@media (min-width: 1200px) {
    .bannerImageDescription{
        border:1px solid #808080;
        text-align:left;
        height: 98px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -ms-line-clamp: 3;
        -ms-box-orient: vertical;
        -o-line-clamp: 3;
        -o-box-orient: vertical;
    }

    .video{
        height: 100% !important;
    }
}

.separator-bottom{
    border-bottom: 1px solid;
}
.btn-untruncate-text{
    width: 100%;
    padding: 2px 5px;
    margin: 5px 0 0 0;
}
.full-width{
    width:100%;
}

.container-ratio {
    aspect-ratio: 1/0.55;
    img {
       max-height: 100%;
    }
}

.btn.button-show-info {
    width: 25px;
    height: 25px;
    padding: 0px;
    font-size: 25px;
    font-weight: 400;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
}
@media (min-width: 992px) {
    /*DESKTOP*/
    .ul-effect {
        position: relative;
    }
    .ul-effect::after {
        content: '';
        border-bottom: 2px solid;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        -webkit-transition: 0.25s ease;
        -moz-transition: 0.25s ease;
        -ms-transition: 0.25s ease;
        -o-transition: 0.25s ease;
        transition: 0.25s ease;
    }
    .ul-effect:hover::after {
        left: 0;
        width: 100%;
    }
}
.overlay, .upper-overlay, .soft-overlay, .search-overlay {
    background: #000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    cursor: pointer;
}
.upper-overlay {
    z-index: 999999991;
}
.soft-overlay {
    background: #FFF;
    opacity: 0;
    z-index: 99;
}
.search-overlay {
    z-index: 100;
}
.overlay-added, .upper-overlay-added, .soft-overlay-added, .search-overlay-added {
    overflow: hidden;
}
/*END GENERAL SETTINGS*/
/*BREADCRUMB*/
.breadcrumb-container ul{
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 5px 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
}
.breadcrumb-container ul li{
    display: inline;
    white-space: nowrap;
}
.breadcrumb-container ul li::after {
    content: "\BB";
    padding: 0 3px;
}
.breadcrumb-container ul li:first-child::after, .breadcrumb-container ul li:last-child::after {
    content: "";
    padding: 0;
}
.breadcrumb-container ul li a {
    text-decoration: none;
}
.breadcrumb-container ul li span {
    color: #4c4c4c;
    -webkit-transition: all .2s linear 0s;
    -moz-transition: all .2s linear 0s;
    -ms-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    transition: all .2s linear 0s;
}
.breadcrumb-container ul li a:hover span {
    color: #2196f3;
}
.breadcrumb-container ul li .breadcrumb-current {
    font-style: italic;
}
@media (max-width: 767px) {
    /*MOBILE XS*/
    .breadcrumb-container ul li::after {
        content: "\BB";
        margin: -1px;
        padding: 0;
    }
    .breadcrumb-container ul li span {
        font-size: 10px;
    }
}
/*END BREADCRUMB*/
/*FORM*/
input.input-box, textarea.input-box, select.input-box{
    border: 1px solid #000 !important;
}
input.input-box, select.input-box{
    height: 32px !important;
}
input.input-box:focus, textarea.input-box:focus, select.input-box:focus{
    border-color: #009587 !important;
}
.was-validated input.input-box:invalid, .was-validated textarea.input-box:invalid, .was-validated select.input-box:invalid, .input-box.has-error, .was-validated input:invalid~.checkbox-material>.check, input.has-error~.checkbox-material>.check {
    border-color: #f44336 !important;
}
input.has-error~label>span.radio-off, input.has-error~label>.treatment-label{
    color: #f44336 !important;
}
.treatment-row.has-error *, .treatment-row.has-error span{
    color: #f44336 !important;
    border-color: #f44336 !important;
}
.was-validated input.input-box:not(.has-error):valid, .was-validated textarea.input-box:not(.has-error):valid, .was-validated select.input-box:not(.has-error):valid, .input-box.is-ok{
    border-color: #0f9d58 !important;
}
.form-control-wrapper .input-box.form-control.has-error~.floating-label {
    color: #f44336;
}
.input-box:not(.empty) {
    padding-left: 5px;
}
input[type="text"], input[type="search"], .input-box {
    background-color : #FFF;
    color: #000;
}
select.input-box:disabled {
    background-color: #E6E6E6;
    border-style: dashed !important;
}
.form-control-wrapper select.input-box {
    height: 28px;
}
.form-control-wrapper .input-box.form-control:not(.empty)~.floating-label {
    top: -13px;
}
.floating-label {
    margin-left: 5px;
}
.form-control-no-animation:not(textarea) {
    height: 28px;
}
.form-control-no-animation {
    padding: 0;
    float: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
}
.inner-addon {
    position: relative;
}
.inner-addon .addon-icon {
    position: absolute;
    top: 3px;
    font-size: 23px;
    pointer-events: none;
}
.inner-addon span[class^='addon-background'], .inner-addon span[class*=' addon-background'] {
    top: 0;
    height: 100%;
    padding: 2px 5px;
    border: 1px solid #000;
}
.inner-addon .addon-background-blue {
    background: #0782d6;
    color: white;
}
.inner-addon .addon-background-black {
    background: #000;
    color: white;
}
.inner-addon .addon-txt {
    position: absolute;
    top: 3px;
    pointer-events: none;
}
.inner-addon .addon-clickable {
    pointer-events: inherit;
    cursor: pointer;
}
.inner-addon .addon-clickable:hover {
    color: #009587;
}
.inner-addon.left-addon .addon-icon,.inner-addon.left-addon .addon-txt  { left:  3px;}
.inner-addon.right-addon .addon-icon, .inner-addon.right-addon .addon-txt { right: 3px;}
.inner-addon.left-addon span[class^='addon-background'],.inner-addon.left-addon span[class*=' addon-background']  { left:  0; border-right: none;}
.inner-addon.right-addon span[class^='addon-background'],.inner-addon.right-addon span[class*=' addon-background']  { right:  0; border-left: none;}
.inner-addon.left-addon input  { padding-left:  30px !important; }
.inner-addon.right-addon input { padding-right: 30px !important; }
/*FORM*/

.messageNoticeVacation {
    font-weight:700;
    color:#0080FF;
}

/*  BACK TO TOP */
@media (min-width: 992px) {
    #toTOP {
        position: fixed;
        bottom: 100px;
        right: 20px;
        z-index: 500;
        cursor: pointer;
        display: none;
    }
    #toTOP span {
        background-color: #75bae8;
        color: white;
        display: inline-block;
        padding: 10px;
        font-size: 30px;
        border-radius: 5px;
        opacity: .8;
        -webkit-transition: 0.25s ease;
        -moz-transition: 0.25s ease;
        -ms-transition: 0.25s ease;
        -o-transition: 0.25s ease;
        transition: 0.25s ease;
    }
    #toTOP span:hover {
        box-shadow: 0 1px 6px rgba(0,0,0,0.2),0 1px 6px rgba(0,0,0,.2);
        opacity: 1;
    }
}
@media (max-width: 991px) {
    #toTOP {
        display: none !important;
    }
}
.recoverPass {
    display: none;
    color: #000;
}

.registerForm {
    display: none;
}

.info-box-blue, .info-box-yellow, .info-box-white {
    border-radius: 5px;
    border: 1px solid #03A9F4;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #000;
    background-color: #B8E7FC;
    padding: 5px;
    text-align: center;
    position: relative;
}
.info-box-yellow {
    border-color: #FDD835;
    background-color: #FFF9C4;
}
.info-box-white {
    border-color: #b9bacb;
    background-color: #FFFFFF;
}
.info-box-blue.with-info-icon::before{
    content: 'i';
    color: #FFF;
    background-color: #03A9F4;
    position: absolute;
    top: -7px;
    left: -7px;
    font-weight: bold;
    font-style: italic;
    border: 1px solid #03A9F4;
    padding: 0 6px;
    border-radius: 20px;
    font-size: 10px;
}
.info-box-blue, .info-box-yellow, .info-box-white *{
    color: inherit;
}

.privacy-advice {
    font-size: 11px;
    color: #888888;
}

.privacy-advice  * {
    font-size: inherit;
    color: inherit;
}

.modal.modal-scrollable {
    overflow-y: auto;
}

.modal-content .modal-body {
    padding-top: 16px !important;
}

.modal-content .modal-body p.important {
    text-align: center;
    font-size: 119%;
}

@media (max-width: 375px) {
    .btn {
        padding-left: 0;
        padding-right: 0;
    }
}

/*** Styles added to fix the issue with zoom in on iphone ***/
/* iphone 3 */
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 1) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
}

/* iphone 4 */
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
}

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
}

/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
}

/* iphone XR */
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
}

/* iphone XS */
@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
}

/* iphone XS Max */
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
}

.without-padding-right {
    padding-right: 0 !important;
}

.font-bold {
    font-weight: bold;
}

.col-min-gutter {
    padding-right: 5px;
    padding-left: 5px;
}

.mb-20px {
    margin-bottom: 20px;
}

.external-sign-in-services  {
    position: relative;
    margin: 15px 0 15px 0;
}
.external-sign-in-services .my-3 {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important;
}
.external-sign-in-services .justify-content-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center!important;
}

.external-sign-in-services .justify-content-center .col-auto {
    flex: 0 0 auto;
    width: auto;
    margin: 0.5rem 0.25rem !important;
    padding: 0 0.5rem;
}

.external-sign-in-services .or-wrap {
    text-align: center;
    position: relative;
}
.external-sign-in-services .or-wrap .text {
    padding: 0 22px;
    position: relative;
    display: inline-block;
    background-color: #fff;
    font-size: 16px;
}
.external-sign-in-services .or-wrap:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid black;
    background: black;
    width: 100%;
    transform: translateY(-50%);
}

.external-sign-in-services .fb-login {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.external-sign-in-services .fb-login img {
    width: 100%;
}

.privacy-form-info {
    font-size: 10px;
}
.privacy-form-info * {
    font-size: inherit;
}

.privacy-form-info ul {
    margin: 0;
}

.modal-backdrop.in {
    background: #000 !important;
}
a, a:hover {
    color: #008cd7;
}
#header {
    text-align: center;
    top: 0;
    position: fixed;
    height: 60px;
    width: 100%;
    z-index: 4;
    background-color: rgba(255,255,255,0.92);
}
#header h1 {
    margin: 10px 0 0 0;
}
#content {
    margin: 60px 0;
}
#welcome {
    background: url(/build/images/welcome.7480b51f.jpg);
    background-position: center;
    height: 412px;
    text-align: center;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    display: table;
    width: 100%;
}
#welcome-text {
    display: table-cell;
    vertical-align: middle;
}
#welcome-text p {
    color: #ffffff;
    font-size: 40px;
    font-weight: 700;
    text-shadow: 0 0 10px rgba(0,0,0,0.5),0 0 10px rgba(0,0,0,0.5),0 0 10px rgba(0,0,0,0.5);
}
#choose-text {
    text-align: center;
    border-bottom: 1px solid grey;
    margin-bottom: 20px;
}
.website {
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
}
.website:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
.website img {
    -webkit-filter: drop-shadow(2px 1px 2px rgba(0,0,0,0.3));
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

@media (max-width: 767px) {
    #welcome {
        background: none;
        height: auto;
    }
    #welcome-text p {
        color: #008cd7;
        font-size: 25px;
        text-shadow: none;
    }
}
